import React from 'react';
import { StyledCard } from 'components/ArticleCard/styled/ArticleCard.styled';

interface ArticleCardProps {
  children: React.ReactNode;
}

function ArticleCard({ children }: ArticleCardProps) {
  return <StyledCard variant="outlined">{children}</StyledCard>;
}

export default ArticleCard;
