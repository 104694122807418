import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { drawerWidth } from 'components/Layout/utils/consts';
import DrawerContent from 'components/Layout/Drawer/DrawerContent/DrawerContent';

interface AppDrawerProps {
  isMobileOpen: boolean;
  handleDrawerClose: () => void;
  handleDrawerTransitionEnd: () => void;
}

function AppDrawer({
  isMobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
}: AppDrawerProps) {
  return (
    <Box
      component="nav"
      sx={{ width: { lg: `${drawerWidth}rem` }, flexShrink: { lg: 0 } }}
    >
      <Drawer
        variant="temporary"
        open={isMobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: `${drawerWidth}rem`,
          },
        }}
      >
        <DrawerContent />
      </Drawer>
      <Drawer
        open
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: `${drawerWidth}rem`,
          },
        }}
      >
        <DrawerContent />
      </Drawer>
    </Box>
  );
}

export default AppDrawer;
