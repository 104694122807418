import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/Layout';
import Grid from '@mui/material/Grid2';
import ArticleCard from 'components/ArticleCard/ArticleCard';
import { t } from 'i18next';
import { Box, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleDialog from 'components/Dialogs/ArticleDialog/ArticleDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchArticles } from 'store/articles/thunks';
import { currentProject } from 'store/projects/selectors';
import { currentOrganization } from 'store/organizations/selectors';
import { isAuthorized } from 'store/authorization/selectors';
import { articles } from 'store/articles/selectors';
import { Article } from 'store/articles/interfaces';

function Dashboard() {
  const isUserAuthorized = useAppSelector(isAuthorized);
  const project = useAppSelector(currentProject);
  const organization = useAppSelector(currentOrganization);
  const allArticles = useAppSelector(articles);
  const dispatch = useAppDispatch();

  const [isArticleDialogOpen, setIsArticleDialogOpen] = useState(false);
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);

  useEffect(() => {
    if (!organization || !project) {
      return;
    }

    if (project?.id && isUserAuthorized) {
      dispatch(fetchArticles({ project: project.id }));
    }
  }, [organization, isUserAuthorized, dispatch, project]);

  const handleArticleDialogClose = () => {
    setIsArticleDialogOpen(false);
    setArticleToEdit(null);
  };

  const handleArticleEditDialogOpen = (article: Article) => {
    setArticleToEdit(article);
    setIsArticleDialogOpen(true);
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6, md: 4, xl: 3 }}>
          <ArticleCard>
            <Box
              onClick={() => setIsArticleDialogOpen(true)}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              gap={1}
            >
              <Typography variant="h6" textAlign="center" color="primary">
                {t('add_new_article')}
              </Typography>
              <AddCircleIcon color="primary" fontSize="large" />
            </Box>
          </ArticleCard>
        </Grid>
        {allArticles?.map((article) => (
          <Grid key={article.id} size={{ xs: 12, sm: 6, md: 4, xl: 3 }}>
            <ArticleCard>
              <Box
                onClick={() => handleArticleEditDialogOpen(article)}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Typography variant="h6" color="primary">
                  {`${t('title')}: ${article.title}`}
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" color="secondary">
                    {`${t('branch')}: ${article.branch || t('master')}`}
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {`${t('position')}: ${article.position}`}
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {`${t('language')}: ${article.language.toUpperCase()}`}
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {`${t('reading_time')}: ${article.readingTime}`}
                  </Typography>
                </Box>
              </Box>
            </ArticleCard>
          </Grid>
        ))}
      </Grid>
      <ArticleDialog
        isDialogOpen={isArticleDialogOpen}
        handleDialogClose={handleArticleDialogClose}
        articleToEdit={articleToEdit}
      />
    </Layout>
  );
}

export default Dashboard;
