import React, { ReactNode, useState } from 'react';
import { t } from 'i18next';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TopBar from 'components/Layout//TopBar/TopBar';
import { drawerWidth } from 'components/Layout/utils/consts';
import AppDrawer from 'components/Layout/Drawer/AppDrawer';
import { Typography, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { currentProject } from 'store/projects/selectors';
import theme from 'config/mui/theme';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportProject } from 'store/projects/thunks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { isRequestPending } from 'store/projects/selectors';

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const project = useAppSelector(currentProject);
  const requestPending = useAppSelector(isRequestPending);

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const dispatch = useAppDispatch();
  const { displayErrorNotification } = useDisplayNotification();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerClose = () => {
    setIsClosing(true);
    setIsMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (isClosing) {
      return;
    }
    setIsMobileOpen(!isMobileOpen);
  };

  const handleExportProjectClick = async () => {
    if (!project) {
      return;
    }

    try {
      await dispatch(exportProject({ id: project.id })).unwrap();
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  return (
    <Box display="flex">
      <TopBar handleDrawerToggle={handleDrawerToggle}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant={isMd ? 'h6' : 'body1'} lineHeight={1.4} py={0.5}>
            {`${t('dashboard', { projectName: project?.name })}`}
          </Typography>
          {project && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleExportProjectClick}
              disabled={requestPending}
            >
              <FileDownloadIcon />
            </IconButton>
          )}
        </Box>
      </TopBar>
      <AppDrawer
        isMobileOpen={isMobileOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
      />
      <Box flexGrow={1} width={{ lg: `calc(100% - ${drawerWidth}rem)` }}>
        <Toolbar />
        <Box p={3}>{children}</Box>
      </Box>
    </Box>
  );
}

export default Layout;
