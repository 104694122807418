import React, { MouseEvent, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projects } from 'store/projects/selectors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { t } from 'i18next';
import { Project } from 'store/projects/interfaces';
import { setCurrentProjectById } from 'store/projects/projects';
import EditNoteIcon from '@mui/icons-material/EditNote';
import IconButton from '@mui/material/IconButton';
import ProjectDialog from 'components/Dialogs/ProjectDialog/ProjectDialog';

function ProjectAddOrSwitchMenu() {
  const allProjects = useAppSelector(projects);
  const dispatch = useAppDispatch();

  const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const handleMenuOpenClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuCloseClick = () => {
    setAnchorEl(null);
  };

  const handleSwitchProject = (project: Project) => {
    dispatch(setCurrentProjectById({ projectId: project.id }));
    setAnchorEl(null);
  };

  const handleProjectEditDialogOpen = (
    event: MouseEvent<HTMLButtonElement>,
    project: Project,
  ) => {
    event.stopPropagation();
    setProjectToEdit(project);
    setIsProjectDialogOpen(true);
  };

  return (
    <>
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          id="project-switch-btn"
          aria-controls={isMenuOpen ? 'project-switch-btn' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleMenuOpenClick}
          endIcon={<ArrowForwardIosIcon />}
        >
          <Typography textTransform="none" variant="body1" fontWeight="bold">
            {t('add_or_switch_project')}
          </Typography>
        </Button>
        <Menu
          id="project-switch-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuCloseClick}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => setIsProjectDialogOpen(true)}>
            <Typography>{t('add_new_project')}</Typography>
          </MenuItem>
          <Divider />
          {allProjects?.map((project) => (
            <MenuItem
              key={project.id}
              onClick={() => handleSwitchProject(project)}
              sx={{ display: 'flex', justifyContent: 'space-between', py: 0.2 }}
            >
              <Typography sx={{ textWrap: 'wrap' }}>{project.name}</Typography>
              <IconButton
                color="inherit"
                edge="end"
                onClick={(event) => handleProjectEditDialogOpen(event, project)}
              >
                <EditNoteIcon />
              </IconButton>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <ProjectDialog
        isDialogOpen={isProjectDialogOpen}
        handleDialogClose={() => setIsProjectDialogOpen(false)}
        projectToEdit={projectToEdit}
      />
    </>
  );
}

export default ProjectAddOrSwitchMenu;
