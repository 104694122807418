import React, { useEffect, forwardRef, useMemo } from 'react';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';
import articleFormSchema from 'components/Forms/ArticleForm/utils/ArticleFormSchema';
import defaultValues from 'components/Forms/ArticleForm/utils/defaultValues';
import {
  Article,
  ArticleOrigin,
  ArticleParent,
} from 'store/articles/interfaces';
import Grid from '@mui/material/Grid2';
import MarkdownEditor from 'components/MarkdownEditor/MarkdownEditor';
import { Branch } from 'store/branches/interfaces';
import { useAppSelector } from 'store/hooks';
import { articles } from 'store/articles/selectors';
import { currentProject } from 'store/projects/selectors';

export type FormData = InferType<typeof articleFormSchema>;

interface ArticleFormProps {
  onSubmit: (data: FormData, setError: UseFormSetError<FormData>) => void;
  articleToEdit?: Article | null;
}

const ArticleForm = forwardRef<HTMLFormElement, ArticleFormProps>(
  ({ onSubmit, articleToEdit }, ref) => {
    const allArticles = useAppSelector(articles);
    const project = useAppSelector(currentProject);

    const {
      control,
      handleSubmit,
      formState: { errors },
      setError,
      reset,
    } = useForm<FormData>({
      resolver: yupResolver(articleFormSchema),
      mode: 'onBlur',
      defaultValues,
    });

    useEffect(() => {
      if (!articleToEdit) {
        return;
      }
      reset(articleToEdit);
    }, [articleToEdit, reset]);

    const filteredArticles = useMemo(() => {
      return (
        allArticles?.filter((article) => article.id !== articleToEdit?.id) || []
      );
    }, [allArticles, articleToEdit]);

    return (
      <Box
        component="form"
        display="flex"
        width="100%"
        flexDirection="column"
        gap={2}
        onSubmit={handleSubmit((data) => onSubmit(data, setError))}
        ref={ref}
      >
        <Grid container spacing={1.5}>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Controller
              name="branch"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="branch-label">{t('branch')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="branch-label"
                    label={t('branch')}
                    variant="outlined"
                    disabled={!!articleToEdit}
                  >
                    <MenuItem key={Branch.master} value={Branch.master}>
                      {t('master')}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Controller
              name="origin"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="origin-label">{t('origin')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="origin-label"
                    label={t('origin')}
                    variant="outlined"
                    disabled={!!articleToEdit}
                  >
                    <MenuItem
                      key={ArticleOrigin.none}
                      value={ArticleOrigin.none}
                    >
                      {t('none')}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <Controller
              name="parent"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="parent-label">
                    {t('parent_article')}
                  </InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="parent-label"
                    label={t('parent_article')}
                    variant="outlined"
                  >
                    <MenuItem
                      key={ArticleParent.none}
                      value={ArticleParent.none}
                    >
                      {t('none')}
                    </MenuItem>
                    {filteredArticles.map((article) => (
                      <MenuItem key={article.id} value={article['@id']}>
                        {article.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  label={t('article_title')}
                  variant="outlined"
                  error={!!errors.title}
                  helperText={getErrorText(errors.title)}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="language-label">{t('language')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="language-label"
                    label={t('language')}
                    variant="outlined"
                    error={!!errors.language}
                  >
                    {project?.availableLanguages?.map((language) => (
                      <MenuItem key={language} value={language}>
                        {t(`language_${language}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Controller
              name="position"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label={t('position')}
                  variant="outlined"
                  error={!!errors.position}
                  helperText={getErrorText(errors.position)}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Controller
              name="readingTime"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label={t('reading_time')}
                  variant="outlined"
                  error={!!errors.readingTime}
                  helperText={getErrorText(errors.readingTime)}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <MarkdownEditor {...field} errors={errors} />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  },
);

ArticleForm.displayName = 'ArticleForm';

export default ArticleForm;
