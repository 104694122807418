import { Card, styled } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '0.6rem',
  height: '15rem',
  padding: '1.2rem',
  backgroundColor: theme.palette.background.white,
  cursor: 'pointer',
  transition: 'border 0.6s ease',
  '&:hover': {
    border: `0.12rem solid ${theme.palette.primary.main}`,
    boxShadow: '0 0.6rem 1.2rem rgba(0, 0, 0, 0.1)',
  },
}));
