import React from 'react';
import Box from '@mui/material/Box';
import LogoToolbar from 'components/Layout/LogoToolbar/LogoToolbar';
import { Button, Divider, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router';
import ProjectAddOrSwitchMenu from 'components/Layout/Drawer/DrawerContent/ProjectAddOrSwitchMenu';
import { t } from 'i18next';
import useResetState from 'hooks/useResetState';

function DrawerContent() {
  const navigate = useNavigate();
  const resetState = useResetState();

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    resetState();
    navigate('/login', { replace: true });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <LogoToolbar />
      <Box>
        <Divider />
        <ProjectAddOrSwitchMenu />
        <Divider />
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button endIcon={<Logout />} onClick={handleLogoutClick}>
            <Typography textTransform="none" variant="body1" fontWeight="bold">
              {t('sign_out')}
            </Typography>
          </Button>
        </Toolbar>
      </Box>
    </Box>
  );
}

export default DrawerContent;
