import { combineReducers } from '@reduxjs/toolkit';
import notifications from 'store/notifications/notifiactions';
import authorization from 'store/authorization/authorization';
import organizations from 'store/organizations/organizations';
import projects from 'store/projects/projects';
import articles from 'store/articles/articles';

export default combineReducers({
  notifications,
  authorization,
  organizations,
  projects,
  articles,
});
