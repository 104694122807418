import React from 'react';
import { RouteObject } from 'react-router';
import OnlyUnauthorizedRoute from 'components/OnlyUnauthorizedRoute/OnlyUnauthorizedRoute';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import Dashboard from 'pages/Dashboard/Dashboard';
import PasswordReset from 'pages/PasswordReset/PasswordReset';
import SignIn from 'pages/SignIn/SignIn';
import SignUp from 'pages/SignUp/SignUp';
import NotFound from 'pages/NotFound/NotFound';
import AccountActivation from 'pages/AccountActivation/AccountActivation';
import PasswordSetup from 'pages/PasswordSetup/PasswordSetup';

export const routes = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/article/:id',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <OnlyUnauthorizedRoute>
        <SignIn />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/register',
    element: (
      <OnlyUnauthorizedRoute>
        <SignUp />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/password-reset',
    element: (
      <OnlyUnauthorizedRoute>
        <PasswordReset />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/activation/:token',
    element: (
      <OnlyUnauthorizedRoute>
        <AccountActivation />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '/password-setup/:token',
    element: (
      <OnlyUnauthorizedRoute>
        <PasswordSetup />
      </OnlyUnauthorizedRoute>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
] as RouteObject[];

export default routes;
