import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
  UnknownAction,
} from '@reduxjs/toolkit';
import { resetAuthorizationState } from 'store/authorization/authorization';
import { resetProjectsState } from 'store/projects/projects';
import { resetOrganizationsState } from 'store/organizations/organizations';
import { resetArticlesState } from 'store/articles/articles';

interface RejectedActionWithCode extends UnknownAction {
  payload: {
    code: number;
  };
}

const isRejectedWithCode = (
  action: UnknownAction,
): action is RejectedActionWithCode =>
  action.payload !== undefined &&
  action.payload !== null &&
  typeof action?.payload === 'object' &&
  'code' in action.payload &&
  typeof action.payload.code === 'number';

const unauthorizedErrorMiddleware: Middleware =
  (middlewareAPI: MiddlewareAPI) => (next) => (action) => {
    const { isAuthorized } = middlewareAPI.getState().authorization;

    if (
      isRejectedWithValue(action) &&
      isRejectedWithCode(action) &&
      action.payload.code === 401 &&
      isAuthorized
    ) {
      localStorage.removeItem('token');
      middlewareAPI.dispatch(resetProjectsState());
      middlewareAPI.dispatch(resetAuthorizationState());
      middlewareAPI.dispatch(resetOrganizationsState());
      middlewareAPI.dispatch(resetArticlesState());

      // TODO: find better way to redirect (eg. pass navigate to middleware)
      window.location.replace('/login');
    }
    return next(action);
  };

export default unauthorizedErrorMiddleware;
