import React, { ReactNode } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { drawerWidth } from 'components/Layout/utils/consts';

interface TopBarProps {
  handleDrawerToggle: () => void;
  children: ReactNode;
}

function TopBar({ children, handleDrawerToggle }: TopBarProps) {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}rem)` },
        ml: { lg: `${drawerWidth}rem` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {children}
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
