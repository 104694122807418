import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

function LogoToolbar() {
  return (
    <Toolbar sx={{ bgcolor: 'primary.main' }}>
      <Typography variant="h6" fontWeight="bold" noWrap color="secondary.main">
        {t('documently')}
      </Typography>
    </Toolbar>
  );
}

export default LogoToolbar;
