export enum Language {
  EN = 'en',
  PL = 'pl',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
  IT = 'it',
  PT = 'pt',
  NL = 'nl',
  ZH = 'zh',
}

export interface ProjectsState {
  isRequestPending: boolean;
  projects: Project[] | null;
  currentProject: Project | null;
}

export interface Project {
  '@id': string;
  '@type': string;
  name: string;
  organization: string;
  availableLanguages: Language[];
  id: string;
}

export type CreateProjectPayload = Pick<
  Project,
  'name' | 'availableLanguages' | 'organization'
>;

export type EditProjectPayload = Pick<
  Project,
  'id' | 'name' | 'availableLanguages'
>;
