import React, { forwardRef } from 'react';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import Grid from '@mui/material/Grid2';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import Markdown from 'components/MarkdownEditor/styled/MarkdownEditor.styled';
import { ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { getErrorText } from 'helpers/getErrorText';
import { isFieldError } from 'helpers/isFieldError';
import getMuiComponents from 'components/MarkdownEditor/utils/getMuiComponents';

interface MarkdownEditorProps extends ControllerRenderProps {
  errors: FieldErrors;
}

const MarkdownEditor = forwardRef<HTMLDivElement, MarkdownEditorProps>(
  ({ errors, onChange, onBlur, value, name }, ref) => {
    const contentError = isFieldError(errors.content)
      ? errors.content
      : undefined;

    return (
      <Grid container spacing={1.5}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            multiline
            fullWidth
            type="text"
            label={t('content')}
            variant="outlined"
            rows={20}
            error={!!errors.content}
            helperText={getErrorText(contentError)}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            inputRef={ref}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Markdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={getMuiComponents()}
          >
            {value}
          </Markdown>
        </Grid>
      </Grid>
    );
  },
);

MarkdownEditor.displayName = 'MarkdownEditor';

export default MarkdownEditor;
