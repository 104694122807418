import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material';

const Markdown = styled(ReactMarkdown)(({ theme }) => ({
  borderRadius: '0.25rem',
  height: '100%',
  padding: '1rem 0.9rem',
  backgroundColor: theme.palette.background.white,
  border: '0.1rem solid rgba(0, 0, 0, 0.25)',

  blockquote: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    padding: '0.5rem',
    borderLeft: `0.5rem solid ${theme.palette.secondary.main}`,
  },
}));

export default Markdown;
